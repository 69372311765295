import { template as template_8e83fa2fb1fb4c1e91e0b50044297003 } from "@ember/template-compiler";
const FKLabel = template_8e83fa2fb1fb4c1e91e0b50044297003(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
