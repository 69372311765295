import { template as template_ba1c1861b4ab4903b8b7da6970bf5659 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ba1c1861b4ab4903b8b7da6970bf5659(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
