import { template as template_e38d3a673a6b465790f592dea4533bed } from "@ember/template-compiler";
const FKText = template_e38d3a673a6b465790f592dea4533bed(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
